require('./bootstrap');

// Use markdown to render images in title and question elements
var showdown = require('showdown');
window.showdown = showdown;

// survey
var Survey = require('survey-jquery');
window.Survey = Survey;

// jszip
window.JSZip = require('jszip');

// pdfMake
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// DataTables
require('datatables.net-bs5');
require('datatables.net-responsive-bs5');
require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.html5.js')

// sweetalert2
import Swal from 'sweetalert2/src/sweetalert2.js';
window.Swal = Swal;

// select2
require('select2');

// jquery-validation
require('jquery-validation');
require('jquery-validation/dist/localization/messages_pl.js');
/* defualt settings */
$.validator.setDefaults({
    errorElement: "div",
    errorPlacement: function (error, element) {
        // Add the `invalid-feedback` class to the error element
        error.addClass("invalid-feedback");

        if (element.prop("type") === "checkbox") {
            error.insertAfter(element.next("label"));
        } else {
            error.insertAfter(element);
        }
    },
    highlight: function (element, errorClass, validClass) {
        $(element).prev().addClass("text-danger");
        $(element).addClass("is-invalid").removeClass("is-valid");
    },
    unhighlight: function (element, errorClass, validClass) {
        $(element).prev().removeClass("text-danger");
        $(element).addClass("is-valid").removeClass("is-invalid");
    }
});

/* other  */
$(document).ready(function () {
    /* tooltip  */

});
